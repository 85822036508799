import type { IMenuItemSDKAction } from '@wix/editor-elements-corvid-utils';
import type { IMenuItemProps } from '../../../Menu.types';
import classes from './style/MenuItem.scss';

export const createSDKAction = (
  item: IMenuItemProps['item'],
  sdkAction?: IMenuItemSDKAction,
) =>
  sdkAction &&
  ((e: React.MouseEvent) => {
    sdkAction?.(e, {
      ...item,
    });
  });

export const setStreachedMegaMenuLeftPosition = (
  liElement: HTMLLIElement,
  positionBoxElement: HTMLDivElement,
) => {
  const { x } = liElement.getBoundingClientRect();
  positionBoxElement.style.setProperty('--item-wrapper-negative-x', `-${x}px`);
};

export const getWixBannerHeight = (): number => {
  const cssVarHeight = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--wix-ads-height');

  return Number.parseInt(cssVarHeight, 10) || 0;
};

export const getVerticalPositionClass = ({
  positionBox,
  label,
}: {
  label: HTMLElement;
  positionBox: HTMLElement;
}) => {
  const boxHeight = positionBox.getBoundingClientRect().height;
  const labelRect = label.getBoundingClientRect();

  const availableSpaceBelow = window.innerHeight - labelRect.bottom;
  const enoughSpaceBelow = availableSpaceBelow >= boxHeight;
  const availableSpaceAbove = labelRect.top - getWixBannerHeight();
  const enoughSpaceAbove = availableSpaceAbove >= boxHeight;

  if (enoughSpaceBelow) {
    return '';
  } else if (enoughSpaceAbove) {
    return classes.aboveMenu;
  } else {
    positionBox.style.setProperty(
      '--position-box-max-height',
      `${availableSpaceBelow}px`,
    );
    return classes.overflow;
  }
};

export const createEventListeners = (
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const showMegaMenu = () => setIsHovered(true);
  const hideMegaMenu = () => setIsHovered(false);
  const toggleMegaMenu = () => setIsHovered(isHovered => !isHovered);

  return {
    onMouseEnter: showMegaMenu,
    onMouseLeave: hideMegaMenu,
    onFocus: (e: React.FocusEvent) => {
      const isFromChildToParent = e.target.nextSibling?.contains(
        e.relatedTarget,
      );

      if (isFromChildToParent) {
        e.stopPropagation();
        hideMegaMenu();
      } else {
        showMegaMenu();
      }
    },
    onBlur: (e: React.FocusEvent) => {
      if (
        e.relatedTarget &&
        !e.currentTarget.contains(e.relatedTarget) &&
        !e.relatedTarget?.contains(e.currentTarget) // Fixes Mobile Safari blur issue where clicking MegaMenu links closes it, blocking navigation. Details: ECL-8426
      ) {
        hideMegaMenu();
      }
    },
    onKeyDown: (e: React.KeyboardEvent) => {
      const { target, currentTarget, key } = e;

      const menuItemLabel = currentTarget.firstChild as HTMLElement;
      if (menuItemLabel !== target) {
        return;
      }

      switch (key) {
        case 'ArrowLeft':
        case 'ArrowRight':
          // const isRtl = getIsRTL(currentTarget.parentElement as HTMLElement);
          const isRtl = false;

          const arrowToSibling = {
            ArrowLeft: isRtl ? 'nextSibling' : 'previousSibling',
            ArrowRight: isRtl ? 'previousSibling' : 'nextSibling',
          } as const;

          const siblingNavigateTo = currentTarget[arrowToSibling[key]];
          (siblingNavigateTo?.firstChild as HTMLElement)?.focus();
          break;
        case 'Enter':
          toggleMegaMenu();
          break;
        case ' ':
          e.preventDefault();
          toggleMegaMenu();
          break;
        default:
          break;
      }
    },
  };
};
