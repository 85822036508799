import * as React from 'react';
import cn from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import type { MenuItemProps } from '@wix/editor-elements-definitions';
import type { FC } from 'react';
import type { SubmenuPreviewMapperProps, SubmenuProps } from '../types';
import { useMenuItemContext } from '../../../common/viewer/Context/MenuItemContext';
import styles from './Submenu.scss';
import Link from '@wix/thunderbolt-elements/src/components/Link/viewer/Link';

type ItemListProps = SubmenuPreviewMapperProps & {
  items: Array<MenuItemProps>;
  isRootLevel?: boolean;
};

const ItemList: FC<ItemListProps> = ({
  items,
  isRootLevel = true,
  previewStateMap,
}) => {
  const isCurrentPage = false; // TODO: Implement logic that picks current page

  return (
    <ul
      className={cn(
        styles.list,
        isRootLevel ? styles.rootList : styles.subList,
      )}
      style={
        {
          '--itemsNumber': items.length,
        } as React.CSSProperties
      }
    >
      {items.map(({ items: subItems, link, label }, i) => {
        return (
          <li key={i}>
            <div
              className={cn(
                styles.itemContainer,
                isRootLevel
                  ? styles.rootItemContainer
                  : styles.subItemContainer,
                { [styles.selected]: isCurrentPage },
              )}
              data-preview={
                previewStateMap?.[isRootLevel ? 'rootItem' : 'subItem']
              }
            >
              <Link
                {...link}
                className={cn(
                  styles.item,
                  subItems?.length && styles.itemWithSubList,
                  isRootLevel ? styles.rootItem : styles.subItem,
                )}
                activateByKey="Enter"
              >
                <span
                  className={
                    isRootLevel ? styles.rootItemLabel : styles.subItemLabel
                  }
                >
                  {label}
                </span>
              </Link>
            </div>
            {subItems?.length && (
              <ItemList
                previewStateMap={previewStateMap}
                items={subItems}
                isRootLevel={false}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

const Submenu: React.FC<SubmenuProps> = props => {
  const { id, previewStateMap } = props;
  const item = useMenuItemContext();

  return (
    <div id={id} {...getDataAttributes(props)} className={cn(styles.root)}>
      <ItemList previewStateMap={previewStateMap} items={item.items!} />
    </div>
  );
};

export default Submenu;
