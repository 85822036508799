import React, { useMemo } from 'react';
import type { IMenuProps } from '../Menu.types';
import { MenuItem } from './components/MenuItem/MenuItem';
import classes from './style/Menu.scss';

const Menu: React.FC<IMenuProps> = props => {
  const { id, items, slots, containerRootClassName, previewStateMap } = props;

  const itemsWithChildren = useMemo(
    () =>
      items.map(item => ({
        ...item,
        children: item.slot && slots[item.slot],
      })),
    [items, slots],
  );

  return (
    <div id={id} className={containerRootClassName}>
      <nav className={classes.root}>
        <ul className={classes.container}>
          {itemsWithChildren.map(item => {
            return (
              <MenuItem
                item={item}
                key={item.id}
                previewState={previewStateMap?.item}
              />
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
