import React, { useEffect, useRef, useState } from 'react';
import classNames from 'clsx';
import Link from '@wix/thunderbolt-elements/components/Link';
import type { IMenuItemProps } from '../../../Menu.types';
import { MenuItemContext } from '../../../../../common/viewer/Context/MenuItemContext';
import {
  createEventListeners,
  createSDKAction,
  getVerticalPositionClass,
  setStreachedMegaMenuLeftPosition,
} from './utils';
import classes from './style/MenuItem.scss';
import { testIds } from '../../constants';

export const MenuItem = (props: IMenuItemProps) => {
  const {
    item,
    onItemClick,
    onItemDblClick,
    onItemMouseIn,
    onItemMouseOut,
    previewState,
  } = props;
  const { label, link, children, forceHovered } = item;
  const [isHovered, setIsHovered] = useState(false);
  const [verticalPositionClass, setVerticalPositionClass] = useState('');
  const labelRef = useRef<HTMLAnchorElement | HTMLDivElement>(null);
  const positionBoxRef = useRef<HTMLDivElement>(null);
  const liRef = useRef<HTMLLIElement>(null);
  const eventListeners = createEventListeners(setIsHovered);

  useEffect(() => {
    if (
      !isHovered ||
      !labelRef.current ||
      !positionBoxRef.current ||
      !liRef.current
    ) {
      return;
    }

    const positionBox = positionBoxRef.current;
    setStreachedMegaMenuLeftPosition(liRef.current, positionBox);
    setVerticalPositionClass(
      getVerticalPositionClass({
        label: labelRef.current,
        positionBox: positionBoxRef.current,
      }),
    );

    return () => {
      positionBox.removeAttribute('style');
    };
  }, [isHovered, positionBoxRef, labelRef, liRef]);

  useEffect(() => {
    setIsHovered(!!forceHovered);
  }, [forceHovered]);

  return (
    <li
      className={classes.itemWrapper}
      ref={liRef}
      {...(isHovered && {
        'data-hovered': true,
      })}
      {...eventListeners}
      data-testid={testIds.menuItem}
    >
      <Link
        {...link}
        ref={labelRef}
        activateByKey="Enter"
        onClick={createSDKAction(item, onItemClick)}
        onMouseEnter={createSDKAction(item, onItemMouseIn)}
        onMouseLeave={createSDKAction(item, onItemMouseOut)}
        onDoubleClick={createSDKAction(item, onItemDblClick)}
        {...(!!children && {
          'aria-haspopup': true,
          ...(!link?.href && {
            role: 'button',
          }),
        })}
        tabIndex={0}
        data-item-label
      >
        <div
          className={classes.labelContainer}
          data-preview={previewState}
          data-testid={testIds.itemLabel}
        >
          <span className={classes.label}>{label}</span>
        </div>
      </Link>
      {children && (
        <div
          className={classes.childrenWrapper}
          role="group"
          aria-label={label}
          data-testid={testIds.childrenWrapper}
        >
          <div
            className={classNames(classes.positionBox, verticalPositionClass)}
            ref={positionBoxRef}
          >
            <div className={classes.alignBox}>
              <MenuItemContext.Provider value={item}>
                {children}
              </MenuItemContext.Provider>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};
